var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        "header-class": "header-class-modal-doc-package",
        id: "modal-task-planning",
        "hide-footer": "",
        "modal-class": "mui-animation",
        fade: false,
      },
      on: { hidden: _vm.emitCloseEvent },
      scopedSlots: _vm._u([
        {
          key: "modal-title",
          fn: function () {
            return [_vm._v("\n\t\t" + _vm._s(_vm.selectedEvent.title) + "\n\t")]
          },
          proxy: true,
        },
      ]),
      model: {
        value: _vm.isOpen,
        callback: function ($$v) {
          _vm.isOpen = $$v
        },
        expression: "isOpen",
      },
    },
    [
      _c(
        "b-form",
        [
          _c(
            "b-row",
            { staticClass: "mb-3" },
            [
              _c(
                "b-col",
                { attrs: { cols: "6" } },
                [
                  _c("change-status-planning", {
                    attrs: { "status-id": _vm.dataToEdit.status },
                    on: { "status:selected": _vm.selectStatus },
                  }),
                ],
                1
              ),
              _c("b-col", { attrs: { cols: "6" } }, [
                _c(
                  "div",
                  { staticClass: "float-right" },
                  [
                    _c(
                      "b-form-checkbox",
                      {
                        attrs: {
                          id: "checkbox-1",
                          name: "checkbox-1",
                          value: _vm.dataToEdit.putInPlanning,
                          "unchecked-value": false,
                        },
                        model: {
                          value: _vm.dataToEdit.putInPlanning,
                          callback: function ($$v) {
                            _vm.$set(_vm.dataToEdit, "putInPlanning", $$v)
                          },
                          expression: "dataToEdit.putInPlanning",
                        },
                      },
                      [_vm._v("\n\t\t\t\t\t\tPut in planning\n\t\t\t\t\t")]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _c(
            "b-form-group",
            {
              attrs: {
                label: _vm.FormMSG(1, "Priority"),
                "label-for": "Priority",
                "label-cols": 3,
                horizontal: true,
              },
            },
            [
              _c("b-form-select", {
                attrs: { options: _vm.priorities },
                model: {
                  value: _vm.dataToEdit.priority,
                  callback: function ($$v) {
                    _vm.$set(_vm.dataToEdit, "priority", $$v)
                  },
                  expression: "dataToEdit.priority",
                },
              }),
            ],
            1
          ),
          _c(
            "b-form-group",
            {
              attrs: {
                label: _vm.FormMSG(2, "Duration"),
                "label-for": "Duration",
                "label-cols": 3,
                horizontal: true,
              },
            },
            [
              _c("div", { staticClass: "d-flex row" }, [
                _c("div", { staticClass: "col-6" }, [
                  _c("div", { staticClass: "d-flex row" }, [
                    _c("div", { staticClass: "col-12" }, [
                      _c(
                        "div",
                        { staticClass: "d-flex flex-row" },
                        [
                          _vm.isProd
                            ? _c("b-form-input", {
                                attrs: {
                                  type: "number",
                                  step: "1",
                                  placeholder: "1",
                                  disabled: !_vm.isProd,
                                },
                                model: {
                                  value: _vm.dataToEdit.dayDuration,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.dataToEdit, "dayDuration", $$v)
                                  },
                                  expression: "dataToEdit.dayDuration",
                                },
                              })
                            : _c("b-form-input", {
                                attrs: {
                                  value: _vm.dataToEdit.dayDuration,
                                  type: "number",
                                  step: "1",
                                  placeholder: "1",
                                  disabled: true,
                                },
                              }),
                          _c(
                            "div",
                            {
                              staticClass:
                                "ml-2 pt-2 task-label text-success font-weight-bold",
                            },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t\t\t\t\t" +
                                  _vm._s(_vm.FormMSG(3, "Days")) +
                                  "\n\t\t\t\t\t\t\t\t"
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "col-6" }, [
                  _c("div", { staticClass: "d-flex row" }, [
                    _c("div", { staticClass: "col-12" }, [
                      _c(
                        "div",
                        { staticClass: "d-flex flex-row" },
                        [
                          _c("b-form-input", {
                            attrs: { type: "time" },
                            model: {
                              value: _vm.dataToEdit.duration,
                              callback: function ($$v) {
                                _vm.$set(_vm.dataToEdit, "duration", $$v)
                              },
                              expression: "dataToEdit.duration",
                            },
                          }),
                          _c(
                            "div",
                            {
                              staticClass:
                                "ml-2 pt-2 task-label text-success font-weight-bold",
                            },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t\t\t\t\t" +
                                  _vm._s(_vm.FormMSG(4, "Hours/d")) +
                                  "\n\t\t\t\t\t\t\t\t"
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]),
              ]),
            ]
          ),
          _c(
            "b-row",
            [
              _c("b-col", [
                _c(
                  "fieldset",
                  { staticClass: "scheduler-border" },
                  [
                    _c("legend", { staticClass: "scheduler-border" }, [
                      _vm._v(_vm._s(_vm.FormMSG(5, "Desired"))),
                    ]),
                    _c(
                      "b-form-group",
                      {
                        staticClass: "bootstrap-date-selection",
                        attrs: {
                          label: "Start Date",
                          "label-for": "DesiredDate",
                          "label-cols": 2,
                          horizontal: true,
                        },
                      },
                      [
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              { attrs: { cols: "8" } },
                              [
                                _c("b-form-datepicker", {
                                  attrs: {
                                    "label-no-date-selected":
                                      "No date selected",
                                    locale: _vm.currentLang,
                                  },
                                  model: {
                                    value: _vm.dataToEdit.desiredStartDate,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.dataToEdit,
                                        "desiredStartDate",
                                        $$v
                                      )
                                    },
                                    expression: "dataToEdit.desiredStartDate",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { cols: "4" } },
                              [
                                _c("b-form-timepicker", {
                                  attrs: {
                                    type: "text",
                                    required: "",
                                    placeholder: "No time selected",
                                  },
                                  model: {
                                    value: _vm.dataToEdit.desiredStartHours,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.dataToEdit,
                                        "desiredStartHours",
                                        $$v
                                      )
                                    },
                                    expression: "dataToEdit.desiredStartHours",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "mt-3" },
            [
              _c("b-col", [
                _c(
                  "div",
                  { staticClass: "float-right" },
                  [
                    _c(
                      "b-button",
                      {
                        staticClass: "mr-2",
                        attrs: { type: "button", disabled: _vm.formBusy },
                        on: { click: _vm.hideModal },
                      },
                      [
                        _vm._v(
                          "\n\t\t\t\t\t\t" +
                            _vm._s(_vm.FormMSG(6, "Close")) +
                            "\n\t\t\t\t\t"
                        ),
                      ]
                    ),
                    _c(
                      "b-button",
                      {
                        attrs: {
                          variant: "success",
                          type: "button",
                          disabled: _vm.formBusy,
                        },
                        on: { click: _vm.save },
                      },
                      [
                        _vm.formBusy
                          ? _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.FormMSG(7, "Modification in progress")
                                )
                              ),
                            ])
                          : _c("span", [
                              _vm._v(_vm._s(_vm.FormMSG(8, "Save"))),
                            ]),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }